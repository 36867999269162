.new-note{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.input-group, .validation{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 10px;
}

label{
    padding-right: 10px;
}

.validation {
    color: red;
}