.new-folder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
}

.form-block{
    padding-bottom: 10px;
    padding-left: 10px;
}